import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

const Divider = ({ label = "", isExpanded = false, hasIcon = false, fontSize = '15px' }) => (
  <div className="divider flex w-full justify-start">
    {label && (
      <h5 style={{fontSize: fontSize}}>
        {hasIcon && (
          <FontAwesomeIcon
            style={{ marginRight: "12px" }}
            size="xs"
            icon={isExpanded ? faChevronDown : faChevronRight}
          />
        )}
        {label}
      </h5>
    )}
  </div>
);

export default Divider;
