import PropTypes from "prop-types";
import useAuthority from "utils/hooks/useAuthority";

const AuthorityCheck = (props) => {
  const {
    userAuthority = [],
    userPermission = [],
    authority = [],
    children,
    permission = [],
  } = props;
  const roleMatched = useAuthority({
    userAuthority,
    userPermission,
    authority,
    permission,
  });

  return roleMatched ? children : <></>;
};

AuthorityCheck.propTypes = {
  userAuthority: PropTypes.array,
  authority: PropTypes.array,
};

export default AuthorityCheck;
