import ConfigProvider, {
  ConfigConsumer,
  ConfigContext,
  defaultConfig,
  useConfig,
} from "./ConfigProvider";

export { ConfigConsumer, ConfigContext, defaultConfig, useConfig };

export default ConfigProvider;
