import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { injectReducer } from "store";

const paginationDefault = {
  page: 1,
  take: 10,
  pageCount: 0,
  itemCount: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

const errorDefault = {
  status: false,
  message: "",
};

const filterDefault = {
  keyword: "",
  filter: {},
};

export function createTableSlices(tableName = "customer", requester) {
  const fetch = createAsyncThunk(`${tableName}/fetch`, (_, { getState }) => {
    try {
      const {
        [tableName]: { filter, pagination },
      } = getState();
      return requester({
        page: pagination.page,
        take: pagination.take,
        ...filter,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  });

  const { reducer, actions } = createSlice({
    name: tableName,
    initialState: {
      data: [],
      loading: false,
      error: { ...errorDefault },
      pagination: { ...paginationDefault },
      filter: { ...filterDefault },
      selectDataRow: {},
    },
    reducers: {
      setPage: (state, { payload }) => {
        state.pagination.page = payload;
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
      setTake: (state, { payload }) => {
        state.pagination.take = payload;
      },
      setFilter: (state, { payload }) => {
        state.filter = { ...payload };
      },
      clearFilter: (state) => {
        state.filter = { ...filterDefault };
      },
      clear: (state) => {
        state.data = [];
        state.loading = false;
        state.error = { ...errorDefault };
        state.pagination = { ...paginationDefault };
        state.filter = { ...filterDefault };
      },
      setSelectRow: (state, action) => {
        state.selectDataRow = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetch.pending, (state) => {
          state.loading = true;
          state.error = { ...errorDefault };
        })
        .addCase(fetch.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload.data;
          state.pagination = payload.meta;
        })
        .addCase(fetch.rejected, (state, { payload }) => {
          state.error = {
            status: true,
            message: payload?.message,
          };
        });
    },
  });
  const {
    clearFilter,
    setFilter,
    setPage,
    setTake,
    setLoading,
    clear,
    setSelectRow,
  } = actions;

  const onPageChange = createAsyncThunk(
    `${tableName}/onPageChange`,
    async function (payload, { dispatch }) {
      dispatch(setPage(payload));
      await dispatch(fetch());
    },
  );

  const onFilterChange = createAsyncThunk(
    `${tableName}/onFilterChange`,
    async function (payload, { dispatch }) {
      dispatch(setFilter(payload));
      await dispatch(fetch());
    },
  );

  const onClearFilter = createAsyncThunk(
    `${tableName}/onClearFilter`,
    async function (_, { dispatch }) {
      dispatch(clearFilter);
      await dispatch(fetch());
    },
  );

  injectReducer(tableName, reducer);

  const selector = (state) => state[tableName];
  return {
    fetch,
    clear,
    selector,
    setLoading,
    onPageChange,
    onFilterChange,
    onClearFilter,
    setSelectRow,
    setTake,
  };
}
