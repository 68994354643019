import classNames from "classnames";
import React from "react";
import { useConfig } from "../../ConfigProvider";
import { useForm } from "../context";

const FormItemNoLabel = React.forwardRef((props, ref) => {
  const {
    children,
    errorMessage,
    invalid,
    className,
    layout,
    asterisk,
    style,
    size,
    extra,
    htmlFor,
  } = props;
  const formContext = useForm();
  const { controlSize } = useConfig();
  const formItemLabelHeight = size || formContext.size || controlSize;
  const formItemLayout = layout || formContext.layout;
  const formItemClass = classNames(
    // 'form-item',
    "h-full",
    formItemLayout,
    className,
    invalid ? "invalid" : "",
  );
  const enterStyle = { opacity: 1, marginTop: 3, bottom: -2 };
  const exitStyle = { opacity: 0, marginTop: -10 };
  const initialStyle = exitStyle;

  return (
    <div ref={ref} className={formItemClass}>
      {children}
      {invalid && (
        <div
          // className="form-explain"
          className="text-red-600"
          initial={initialStyle}
          animate={enterStyle}
          exit={exitStyle}
          transition={{ duration: 0.15, type: "tween" }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
});

export default FormItemNoLabel;
