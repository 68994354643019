import { LOV } from "constants/api.constant";
import HttpService from "./HttpService";

export const getLovByCodeType = async (codeType) => {
  const result = await HttpService.get(
    `masterdata/api/lov/code-type?value=${codeType}`,
  );
  return result?.data ?? [];
};

export const getProductAttributes = async () => {
  return await getLovByCodeType(LOV.PRODUCT_ATTRIBUTES);
};
