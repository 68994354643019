import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

const StickyFooter = (props) => {
  const { children, className, stickyClass, ...rest } = props;

  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      {
        threshold: [1],
      },
    );

    observer.observe(cachedRef);

    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  return (
    <div
      className={classNames(
        "sticky -bottom-1",
        className,
        isSticky && stickyClass,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
};

StickyFooter.propTypes = {
  stickyClass: PropTypes.string,
};

export default StickyFooter;
