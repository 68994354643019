export const ROOT = "/";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const UI_COMPONENTS_PREFIX_PATH = "/ui-components";
export const PAGES_PREFIX_PATH = "/pages";
export const DOCS_PREFIX_PATH = "/docs";

export const APP_ROUTE = "/home";
export const CUSTOMER_ROUTE = "/customers";

export const ADMIN_USERS_MANAGEMENT = "/admin/users";
export const ADMIN_PERMISSIONS_MANAGEMENT = "/admin/permissions";
export const ADMIN_ROLE_DETAIL = "/admin/role/:id";
export const ADMIN_CONFIGURATION_MANAGEMENT = "/admin/configuration";
export const LIST_PERMISSION_MANAGEMENT = "/admin/list-permissions";
export const ADMIN_QUOTE_MANAGEMENT = "/admin/quote";
export const ADMIN_THEME_MANAGEMENT = "/admin/theme";

export const NOTIFICATION = "/notification";

export const USER_WEBSITE_MANAGEMENT = "/user/website";
export const USER_WEBSITE_UPDATE = "/user/website/:id";
export const USER_WEBSITE_ADD = "/user/website/create";
export const BRIDE_GROOM_MANAGEMENT = "/user/bride-groom";
export const BRIDESMAIDS_GROOMAN_MANAGEMENT = "/user/bridesmaids-grooman";
export const LOVE_STORY_MANAGEMENT = "/user/love-story";
export const WEDDING_EVENT_MANAGEMENT = "/user/wedding-event";
export const WEDDING_IMAGE_MANAGEMENT = "/user/wedding-image";
export const GUEST_MANAGEMENT = "/user/guest";
export const WISHES_MANAGEMENT = "/user/wishes";


