export const APP_NAME = "OursWedding.vn";
export const PERSIST_STORE_NAME = "admin";
export const REDIRECT_URL_KEY = "redirectUrl";
export const DEFAULT_ERROR_MESSAGE = "Có lỗi hệ thống xảy ra!";
export const BAD_REQUEST_ERROR_MESSAGE = "Thiếu thông tin!";
export const SIGNIN_TYPE = {
  DEFAULT: "default",
};

export const DEFAULT_THUMNAIL = "https://static.ourswedding.vn/20240419/default_image-1713537327195-61de7589-6b17-48d1-bcfc-e728d1546b48.png";
export const BRIDE_GROOM_TYPE = {
  bride: 'cô dâu',
  groom: 'chú rể'
}

export const BRIDESMAIDS_GROOMAN_TYPE = {
  bridesmaids: 'phù dâu',
  groomsman: 'phù rể'
}
