export const OrderInfo = ({
  code = " - ",
  createdUser = " - ",
  createdAt = " - ",
  status = "",
  fileUrl,
  showSync = false,
  onSyncPress,
}) => {
  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-auto mb-8">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-4 py-2">
            Mã phiếu lệnh
          </th>
          <th scope="col" className="px-4 py-2 truncate">
            Ngày lập phiếu
          </th>
          <th scope="col" className="px-4 py-2 truncate">
            Người lập phiếu
          </th>
          <th scope="col" className="px-4 py-2">
            Trạng thái
          </th>
          <th scope="col" className="px-4 py-2">
            Hành động
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-white dark:bg-gray-800">
          <th
            scope="row"
            className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {code}
          </th>
          <td className="px-4 py-4">{createdAt}</td>
          <td className="px-4 py-4">{createdUser}</td>
          <td className="px-4 py-4">{status}</td>
          <td className="px-4 py-4">
            <div className="flex items-center gap-4">
              {fileUrl ? (
                <a
                  className="row font-bold cursor-pointer text-blue-500"
                  href={fileUrl}
                  target="_blank"
                >
                  Tải xuống
                </a>
              ) : (
                <>-</>
              )}
              {showSync && (
                <span
                  className="font-bold cursor-pointer text-red-500"
                  onClick={onSyncPress}
                >
                  Sync
                </span>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
