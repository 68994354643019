import { useEffect, useState } from "react";
import Select from "./SelectFloat";

const mapListToOptions = (data) => {
  return data?.map((item) => ({
    value: item.code,
    label: item.code + " - " + item.description,
  }));
};

export const SelectProductCode = ({ disabled = false, onChange, value }) => {
  const [options, setOptions] = useState([]);

  const handleChange = (value) => {
    onChange && onChange(value);
  };

  const fetchOptions = async () => {
    
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const selected = options.find(({ value: itemValue }) => value === itemValue);

  return (
    <Select
      cacheOptions
      isDisabled={disabled}
      placeholder="Nhập"
      options={options}
      value={selected}
      onChange={handleChange}
    />
  );
};
