import dayjs from "dayjs";
import i18n from "i18next";
import { dateLocales } from "locales";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function useLocale() {
  const locale = useSelector((state) => state.locale.currentLang);

  useEffect(() => {
    const formattedLang = locale.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    if (locale !== i18n.language) {
      i18n.changeLanguage(formattedLang);
    }
    try {
      dateLocales[formattedLang]().then(() => {
        dayjs.locale(formattedLang);
      });
    } catch (error) {}
  }, [locale]);

  return locale;
}

export default useLocale;
