import HttpService from "./HttpService";
export const getAllNotification = (data) =>
  HttpService.get("api/v1/notification", { params: data });
export const readNotification = (id) =>
  HttpService.put(`api/v1/notification/read/${id}`);
export const sendNotificationByTemplate = (data) =>
  HttpService.post("api/v1/notification/send-user-with-template", data);
export const registerToken = (data) =>
  HttpService.post("api/v1/notification/register-token", data);
export const deleteToken = (username) =>
  HttpService.delete(`api/v1/notification/deleteToken/${username}`);
export const readAllNotification = (username) =>
  HttpService.put(`api/v1/notification/read-all-notification`, {
    params: { username: username },
  });
export const UnReadNotification = (id) =>
  HttpService.put(`api/v1/notification/unmark-as-read-notification/${id}`);
