import { SelectFloat } from "components/ui";

export const ApprovalSelector = () => {
  return (
    <>
      <h5 className="mb-4">Thông tin phê duyệt</h5>
      <section className="grid md:grid-cols-2 md:gap-4">
        <div className="form-item">
          <label className="form-label mb-2">
            <span className="text-red-500 ltr:mr-1 rtl:ml-1">*</span>
            Quản lý trực tiếp
          </label>
          <SelectFloat placeholder="Chọn" />
        </div>
        <div className="form-item">
          <label className="form-label mb-2">
            <span className="text-red-500 ltr:mr-1 rtl:ml-1">*</span>
            Cấp có thẩm quyền
          </label>
          <SelectFloat placeholder="Chọn" />
        </div>
      </section>
    </>
  );
};
