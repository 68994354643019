import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

const DividerExt = ({ label = "", isExpanded = false }) => (
  <div className="divider">
    {label && (
      <h6>
        <FontAwesomeIcon
          style={{ marginRight: "12px" }}
          size="xs"
          icon={isExpanded ? faChevronDown : faChevronRight}
        />
        {label}
      </h6>
    )}
  </div>
);

export default DividerExt;
