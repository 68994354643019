import classNames from "classnames";
import { forwardRef } from "react";

const SvgIcon = forwardRef((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <span ref={ref} className={classNames("inline-flex", className)} {...rest}>
      {children}
    </span>
  );
});

export default SvgIcon;
