import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDistrictByProvinceId,
  getProvinces,
  getWardByDistrictId,
} from "services/CommonService";
import {
  getLovByCodeType,
  getProductAttributes,
} from "services/MasterDataService";

const mapDataToOptions = ({ code, name }) => ({ label: name, value: code });
const mapDataMasterDataToOptions = ({ valueCode, valueName }) => ({
  label: valueName,
  value: valueCode,
});

export const initialState = {
  provinces: {},
  districts: {},
  wards: {},
  productAttributes: [],
  valueCodes: {},
};

export const fetchProvinces = createAsyncThunk(
  "common/fetchProvinces",
  async () => {
    const data = await getProvinces();
    return { vn: data.map(mapDataToOptions) };
  },
);

export const fetchDistrictsByProvinceId = createAsyncThunk(
  "common/fetchDistrictsByProvinceId",
  async (id) => {
    const data = await getDistrictByProvinceId(id);
    return { [id]: data.map(mapDataToOptions) };
  },
);

export const fetchWardsByDistrictId = createAsyncThunk(
  "common/fetchWardsByDistrictId",
  async (id) => {
    const data = await getWardByDistrictId(id);
    return { [id]: data.map(mapDataToOptions) };
  },
);

export const fetchProductAttributes = createAsyncThunk(
  "common/fetchProductAttributes",
  async () => {
    const data = await getProductAttributes();
    return data.map(mapDataMasterDataToOptions);
  },
);

export const fetchCodeTypeValues = createAsyncThunk(
  "common/fetchCodeTypeValues",
  async (codeType) => {
    const data = await getLovByCodeType(codeType);
    return { [codeType]: data.map(mapDataMasterDataToOptions) };
  },
);

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.provinces = { ...state.provinces, ...action.payload };
    });
    builder.addCase(fetchDistrictsByProvinceId.fulfilled, (state, action) => {
      state.districts = { ...state.districts, ...action.payload };
    });
    builder.addCase(fetchWardsByDistrictId.fulfilled, (state, action) => {
      state.wards = { ...state.wards, ...action.payload };
    });
    builder.addCase(fetchProductAttributes.fulfilled, (state, action) => {
      //state.productAttributes = {...state.productAttributes, ...action.payload};
      state.productAttributes = action.payload;
    });
    builder.addCase(fetchCodeTypeValues.fulfilled, (state, action) => {
      state.valueCodes = { ...state.valueCodes, ...action.payload };
    });
  },
});

export default commonSlice.reducer;
