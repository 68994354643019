import { useMemo } from "react";

function useAuthority({
  userAuthority = [],
  userPermission = [],
  authority = [],
  permission = [],
}) {
  const roleMatched = useMemo(() => {
    return authority.some(
      (role) => userAuthority.includes(role) || userPermission.includes(role),
    );
  }, [authority, userAuthority]);
  const permissionMatched = useMemo(() => {
    return permission.some((perms) => userPermission.includes(perms));
  }, [permission, userPermission]);

  return roleMatched || permissionMatched;
}

export default useAuthority;
